.about {
    background-color: #f6f9fe;
}

.about h2 {
    margin-bottom: 1em;
    padding: 10px;
    color: #062b5b;
    font-family: 'Roboto', sans-serif;

    font-size: 1.4em;
    letter-spacing: 1px;
    font-weight: 700;
    padding: 8px 20px;
    margin: 0;
        margin-bottom: 0px;
    background: #e4e5e7a6;
    color: #062b5b;
    display: inline-block;
    text-transform: uppercase;
    border-radius: 50px;
    
    margin: 2rem 0;
    
    font-family: 'Roboto', sans-serif;
}

.about h3, h4, h5 {
    font-family: 'Roboto', sans-serif;
}

.col1 {    
    text-align: left;
    font-family: 'Roboto', sans-serif;
    padding-left: 5rem;
}

.col2 {
    padding: 10rem 0 5rem;
}

.abt-img{
    max-width: 35rem;
    margin-left: 10rem;
    border-radius: 2rem;
}

.cohort {
    text-align: left;
    margin-bottom: 5rem;
}

@media only screen and (max-width: 786px) {
    .col1 {
        padding: 0 2rem;
    }

    .abt-img{
        max-width: 20rem;
        margin: 0;
    }

    .col2 {
        padding: 5rem 0 0 ;
    }

    .cohort {
        padding: 0 2rem;
        margin-bottom: 0;
    }
}
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.hero {
    background-color: #0a1e32;
    height: 100vh;
    color: white;
}

.hero h3 {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 2rem;
}

.hero h1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 3.25rem;
    margin-top: 5rem;
}

.img-hero {
    width: 40vw;
    position: relative;
    top: 10rem;
    left: 5rem;
}

@media only screen and (max-width: 1006px) {
    .hero {
        height: 120vh;
    }
    .img-hero {
        left: 0;
        top: 5vh;
    }
}

@media only screen and (max-width: 862px) {
    .img-hero {
        width: 40vw;
    }
}

@media only screen and (max-width: 480px) {
    .img-hero {
        width: 80vw;
    }
    .hero {
        height: 150vh;
    }
}
.about {
    background-color: #f6f9fe;
}

.about h2 {
    margin-bottom: 1em;
    padding: 10px;
    color: #062b5b;
    font-family: 'Roboto', sans-serif;

    font-size: 1.4em;
    letter-spacing: 1px;
    font-weight: 700;
    padding: 8px 20px;
    margin: 0;
        margin-bottom: 0px;
    background: #e4e5e7a6;
    color: #062b5b;
    display: inline-block;
    text-transform: uppercase;
    border-radius: 50px;
    
    margin: 2rem 0;
    
    font-family: 'Roboto', sans-serif;
}

.about h3, h4, h5 {
    font-family: 'Roboto', sans-serif;
}

.col1 {    
    text-align: left;
    font-family: 'Roboto', sans-serif;
    padding-left: 5rem;
}

.col2 {
    padding: 10rem 0 5rem;
}

.abt-img{
    max-width: 35rem;
    margin-left: 10rem;
    border-radius: 2rem;
}

.cohort {
    text-align: left;
    margin-bottom: 5rem;
}

@media only screen and (max-width: 786px) {
    .col1 {
        padding: 0 2rem;
    }

    .abt-img{
        max-width: 20rem;
        margin: 0;
    }

    .col2 {
        padding: 5rem 0 0 ;
    }

    .cohort {
        padding: 0 2rem;
        margin-bottom: 0;
    }
}
.modules {
    max-width: 70%;
    margin: auto;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 5rem;
}

.modules h2 {
    margin-bottom: 1em;
    padding: 10px;
    color: #062b5b;
    font-family: 'Poppins', sans-serif;

    font-size: 1.4em;
    letter-spacing: 1px;
    font-weight: 700;
    padding: 8px 20px;
    margin: 0;
        margin-bottom: 0px;
    background: #e4e5e7a6;
    color: #062b5b;
    display: inline-block;
    text-transform: uppercase;
    border-radius: 50px;
    
    margin: 2rem 0;
}

.modules h3 {
    margin-bottom: 1em;
    padding: 10px;
    color: #062b5b;
    font-family: 'Roboto', sans-serif;

    font-size: 1.2em;
    letter-spacing: 1px;
    font-weight: 700;
    padding: 8px 20px;
    margin: 0;
        margin-bottom: 0px;
    background: #e7f1fd;
    color: #062b5b;
    display: inline-block;
    text-transform: uppercase;
    border-radius: 50px;
    
    margin: 2rem 0;
}

.mt-auto {
    border-radius: 15px;
    padding: 0.5rem 0;
    background-color: #0a1e32;
}

.bx {
    font-size: 60px;
}

.bx:hover {
    -webkit-animation: tada 1.5s ease infinite;
            animation: tada 1.5s ease infinite;
}

.carddd {
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);

    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    border-radius: 10px;
  }
  .carddd:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #0a1e32;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transition-property: transform;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    border-radius: 10px;
  }
  .carddd:hover, .carddd:focus, .carddd:active {
    color: white;
  }
  .carddd:hover:before, .carddd:focus:before, .carddd:active:before {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }

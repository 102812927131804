.modules {
    max-width: 70%;
    margin: auto;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 5rem;
}

.modules h2 {
    margin-bottom: 1em;
    padding: 10px;
    color: #062b5b;
    font-family: 'Poppins', sans-serif;

    font-size: 1.4em;
    letter-spacing: 1px;
    font-weight: 700;
    padding: 8px 20px;
    margin: 0;
        margin-bottom: 0px;
    background: #e4e5e7a6;
    color: #062b5b;
    display: inline-block;
    text-transform: uppercase;
    border-radius: 50px;
    
    margin: 2rem 0;
}

.modules h3 {
    margin-bottom: 1em;
    padding: 10px;
    color: #062b5b;
    font-family: 'Roboto', sans-serif;

    font-size: 1.2em;
    letter-spacing: 1px;
    font-weight: 700;
    padding: 8px 20px;
    margin: 0;
        margin-bottom: 0px;
    background: #e7f1fd;
    color: #062b5b;
    display: inline-block;
    text-transform: uppercase;
    border-radius: 50px;
    
    margin: 2rem 0;
}

.mt-auto {
    border-radius: 15px;
    padding: 0.5rem 0;
    background-color: #0a1e32;
}

.bx {
    font-size: 60px;
}

.bx:hover {
    animation: tada 1.5s ease infinite;
}

.carddd {
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);

    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    border-radius: 10px;
  }
  .carddd:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #0a1e32;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    border-radius: 10px;
  }
  .carddd:hover, .carddd:focus, .carddd:active {
    color: white;
  }
  .carddd:hover:before, .carddd:focus:before, .carddd:active:before {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }